import React from "react";
// import { useScopedI18n } from "@/app/locales/client";
import {
  SmartTagsUsageOnPosAndAppsChart,
  SmartTagsUsageOnAllPosChart,
  SmartTagsUsedAtPosChart,
  SmartTagsUsageOnAppPosHistoricalChart,
  TotalSensorsUsedOnCashiersHistoricalChart,
} from "./charts";
import { useTranslation } from "react-i18next";

function CheckoutsTab() {
  // const t = useScopedI18n("analytics");
  const { t } = useTranslation();

  return (
    <>
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-6 ">
        <div className="bg-white rounded-xl  mb-5  py-4 px-2">
          <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2 ">
            {t("analytics.totalGroupedSensorUsageOnPOS")}
          </h1>
          <SmartTagsUsageOnPosAndAppsChart />
        </div>
        <div className="bg-white rounded-xl  mb-5 py-4 px-2">
          <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
            {t("analytics.totalGroupedSensorUsageOnCashiers")}
          </h1>
          <SmartTagsUsageOnAllPosChart />
        </div>
      </div>

      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 ">
        <div className="bg-white rounded-xl mb-5 py-4 px-2">
          <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
            {t("analytics.sensorsUsedAtPOS")}
          </h1>

          <SmartTagsUsedAtPosChart />
        </div>
        <div className="bg-white rounded-xl mb-5 py-4 px-2">
          <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
            {t("analytics.sensorsUsedAtCashier")}
          </h1>

          <SmartTagsUsageOnAppPosHistoricalChart />
        </div>
      </div>

      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 ">
        <div className="bg-white rounded-xl mb-5 py-4">
          <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
            {t("analytics.totalSensorUsedOnCashiers")}
          </h1>
          <TotalSensorsUsedOnCashiersHistoricalChart />
        </div>
      </div>
    </>
  );
}

export default CheckoutsTab;

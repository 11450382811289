import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { MenuItemType } from "./navItemsData";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function NavMain({ items }: { items: MenuItemType[] }) {
  const location = useLocation();
  const { setOpenMobile, isMobile } = useSidebar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <SidebarGroup>
      <SidebarMenu>
        {items.map((item) => (
          <SidebarMenuItem
            key={item.title}
            className={clsx(
              " flex items-center  py-2 px-2 text-white rounded-lg  ",
              {
                "bg-white/10":
                  !!item.url && location.pathname.endsWith(item.url),
                "hover:bg-white/10": item.isActive,
                "cursor-not-allowed": !item.isActive,
              }
            )}
          >
            <SidebarMenuButton
              onClick={() => {
                item.isActive && item.url && navigate(item.url);
                if (isMobile) {
                  setOpenMobile(false);
                }
              }}
              disabled={!item.isActive}
              tooltip={t(`dashboardMenu.${item.title}`)}
            >
              {item.icon && <item.icon />}
              <span className="capitalize">
                {t(`dashboardMenu.${item.title}`)}
              </span>
            </SidebarMenuButton>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}

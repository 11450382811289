import { serviceAPI } from '@/utils';

type ParamType = {
    startDate: string;
    endDate: string;
    storeId: string;
    accessToken: string;
};

// Total number of sensors
export async function fetchTotalSensorsData({
    startDate,
    endDate,
    storeId,
    accessToken
}: ParamType) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    // Fetch data using the accessToken
    const res = await serviceAPI('analytic').get(
        `/storeSensors?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }
    const sensorData = await res.data;

    const sensorValues = [
        {
            data: [
                sensorData.Fashion_Sensors,
                sensorData.Bottle_Sensors,
                sensorData.Safer_Sensors,
                sensorData.Mini_Safer_Sensors,
                sensorData.Spider_Sensors,
                sensorData.Other_Sensors
            ]
        }
    ];

    return { sensorValues, ...sensorData };
}

// Number of Used sensors

export async function fetchUsedSensorsData({
    startDate,
    endDate,
    storeId,
    accessToken
}: ParamType) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    const res = await serviceAPI('analytic').get(
        `/usedStoreSensors?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }
    const sensorData = await res.data;

    const sensorValues = [
        {
            data: [
                sensorData.Bottle_Sensors,
                sensorData.Fashion_Sensors,
                sensorData.Mini_Safer_Sensors,
                sensorData.Other_Sensors,
                sensorData.Safer_Sensors,
                sensorData.Spider_Sensors
            ]
        }
    ];

    return { sensorValues, ...sensorData };
}

// Assigned vs Used sensors
export async function fetchAssignedVsUsedSensorsData({
    startDate,
    endDate,
    storeId,
    accessToken
}: ParamType) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    const res = await serviceAPI('analytic').get(
        `/sensorUsageStore?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }
    const sensorData = await res.data;

    const sensorValues = [sensorData.All_Sensors, sensorData.Used_Sensors];

    return { sensorValues, ...sensorData };
}

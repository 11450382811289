import { Chart } from "@/components";
import getChartOptions from "@/components/chart/chartOptions";
import { useSensorTypeUsageCashiersData } from "@/hooks";
import { useState } from "react";
// import { createSensorsHistory } from "@/hooks/data/mock";
import { DatePickerWithPresets } from "@/components/ui/DatePicker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";
import { useStoreContext } from "@/contexts";

function SmartTagsUsageOnAllPosChart() {
  const { selectedStoreId } = useStoreContext();
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  console.log("selectedStoreId", selectedStoreId);

  const { data, error, isLoading } = useSensorTypeUsageCashiersData({
    startDate: date?.from?.toISOString().split(".")[0] || "",
    endDate: date?.to?.toISOString().split(".")[0] || "",
    storeId: selectedStoreId.toString() || "",
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return (
    <div className="relative pt-2 mt-6 ">
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-3 mb-3 absolute top-[-12px] left-4 right-10 w-auto  md:w-full">
        <DatePickerWithPresets dateRange={date} setDateRange={setDate} />
      </div>
      <Chart
        type={"bar"}
        data={data.values || []}
        options={getChartOptions(data.cashierNames, "bar", undefined, true)}
      />
    </div>
  );
}

export default SmartTagsUsageOnAllPosChart;

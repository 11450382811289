import { Chart } from "@/components";
import getChartOptions from "@/components/chart/chartOptions";
import { createSensorsHistory } from "@/hooks/data/mock";
import React, { useState } from "react";
import { DatePickerWithPresets } from "@/components/ui/DatePicker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";

function SmartTagsUsageOnAppPosHistoricalChart() {
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  const totalHistoricalDataOnAllCashiers = createSensorsHistory(
    ["Kasse1", "Kasse2", "Kasse3", "Kasse4", "Kasse5"],
    60
  );

  return (
    <div className="relative pt-2 mt-6  ">
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-3 mb-3 md:absolute px-3 top-[-15px] left-1  w-full">
        <DatePickerWithPresets dateRange={date} setDateRange={setDate} />
      </div>
      <Chart
        type={"line"}
        data={totalHistoricalDataOnAllCashiers.sensorsHistory}
        options={getChartOptions(
          totalHistoricalDataOnAllCashiers.lastSixMonths,
          "line"
        )}
      />
    </div>
  );
}

export default SmartTagsUsageOnAppPosHistoricalChart;

import {
    ChartBar,
    Tag,
    Rss,
    ShoppingCart,
    Users,
    Wifi,
    LifeBuoy,
    type LucideIcon
} from 'lucide-react';

export type MenuItemType = {
    title: string;
    icon?: LucideIcon;
    url: string;
    isActive?: boolean;
    // subItems?: ItemType[];
};

export const links: MenuItemType[] = [
    {
        title: 'analytics',
        url: '/analytics',
        icon: ChartBar,
        isActive: true
    },
    {
        title: 'stores',
        url: '/stores',
        icon: ShoppingCart,
        isActive: true
    },
    {
        title: 'sensors',
        url: '/smart-tags',
        icon: Tag,
        isActive: true
    },
    {
        title: 'detacherUnits',
        url: '/detacher-units',
        icon: Rss,
        isActive: true
    },

    {
        title: 'users',
        url: '/users',
        icon: Users,
        isActive: false
    },
    {
        title: 'wifiConfigs',
        url: '/wifi-configs',
        icon: Wifi,
        isActive: true
    },
    {
        title: 'feedbackSupport',
        url: '/feedback-Support',
        icon: LifeBuoy,
        isActive: false

        //   subItems: [
        //     { title: "Templates", url: "/admin/templates" },
        //     { title: "Questions", url: "/admin/questions" },
        //     { title: "Settings", url: "/admin/settings" },
        //   ],
    }
];

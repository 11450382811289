import { useEffect, useState } from "react";
import { Chart } from "@/components";
import getChartOptions from "@/components/chart/chartOptions";
import { createSensorsHistory } from "@/hooks/data/mock";
import { DatePickerWithPresets } from "@/components/ui/DatePicker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";

const totalHistoricalData = createSensorsHistory(
  ["Safer", "Mini Safer", "Fashion", "Bottle", "Spider"],
  80
);

function NumberOfSmartTagsHistoricalChart() {
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  interface SensorHistory {
    name: string;
    data: number[];
  }

  const [sensorData, setSensorData] = useState<{
    sensorsHistory: SensorHistory[];
    lastSixMonths: string[];
  }>({
    sensorsHistory: [],
    lastSixMonths: [],
  });

  useEffect(() => {
    const totalHistoricalData = createSensorsHistory(
      ["Safer", "Mini Safer", "Fashion", "Bottle", "Spider"],
      80
    );

    setSensorData(totalHistoricalData);
  }, []);

  return (
    <div className="relative pt-2 mt-5  ">
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-3 mb-3 md:absolute px-3 top-[-15px] left-4  w-full">
        <DatePickerWithPresets dateRange={date} setDateRange={setDate} />
      </div>
      {sensorData.lastSixMonths && sensorData.lastSixMonths.length > 0 && (
        <Chart
          type={"line"}
          data={totalHistoricalData.sensorsHistory}
          options={getChartOptions(totalHistoricalData.lastSixMonths, "line")}
        />
      )}
    </div>
  );
}

export default NumberOfSmartTagsHistoricalChart;

import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
  QueryCache,
} from "react-query";
import { useGlobalNotification } from "./ErrorContext";

const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const { setGlobalError } = useGlobalNotification();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error: any, query) => {
        if (query.queryKey) {
          console.error(`Error in query [${query.queryKey}]:`, error);
        }
        setGlobalError(
          error.message || "An error occurred while fetching data"
        );
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: any, _, __, mutation) => {
        const { mutationKey } = mutation.options;
        console.error(`Error in mutation [${mutationKey}]:`, error);
        setGlobalError(error.message || "Failed to process mutation");
      },
    }),
    defaultOptions: {
      queries: {
        onError: (error: any) => {
          setGlobalError(error.message || "An error occurred");
        },
      },
      mutations: {
        onError: (error: any) => {
          setGlobalError(error.message || "Failed to process mutation");
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;

import { lazy, Suspense } from 'react';
import './App.css';
import {
    NotificationProvider,
    ReactQueryProvider,
    SessionProvider,
    StoreProvider
} from '@/contexts';
import { Routes, Route } from 'react-router-dom';
import { Layout, ProtectedRoute } from '@/components';
import { useLocalStorage } from './hooks';
import setTheme from './utils/setTheme';
import themes from './theme';
import { ThemeType } from './types';
import { Toaster } from './components/ui/toaster';

// Auth pages
const Login = lazy(() => import('@/pages/auth/Login'));
const ResetPassword = lazy(() => import('@/pages/auth/ResetPassword'));
const CheckInbox = lazy(() => import('@/pages/auth/CheckInbox'));

// Dashboard pages
const Analytics = lazy(() => import('@/pages/dashboard/Analytics'));
const Stores = lazy(() => import('@/pages/dashboard/Stores'));
const Wifi = lazy(() => import('@/pages/dashboard/Wifi'));
const DetacherUnits = lazy(() => import('@/pages/dashboard/DetacherUnits'));
const SmartTags = lazy(() => import('@/pages/dashboard/SmartTags'));
const Users = lazy(() => import('@/pages/dashboard/Users'));

// Error pages
const NotFound = lazy(() => import('@/pages/errors/404'));

function App() {
    const [merchant_id] = useLocalStorage('merchant_id', '');

    const merchantId = merchant_id ? merchant_id : '0';
    if (merchantId !== undefined) {
        setTheme(themes[merchantId as keyof typeof themes] as ThemeType);
    }

    return (
        <div className="bg-background">
            <SessionProvider>
                <Suspense fallback={<div>Loading...</div>}>
                    <NotificationProvider>
                        <ReactQueryProvider>
                            <StoreProvider>
                                <Routes>
                                    <Route path="/" element={<Login />} />
                                    <Route
                                        path="/reset-password"
                                        element={<ResetPassword />}
                                    />
                                    <Route
                                        path="/check-inbox"
                                        element={<CheckInbox />}
                                    />
                                    <Route element={<ProtectedRoute />}>
                                        <Route path="/" element={<Layout />}>
                                            <Route
                                                path="/analytics"
                                                element={<Analytics />}
                                            />
                                        </Route>
                                        <Route path="/" element={<Layout />}>
                                            <Route
                                                path="/stores"
                                                element={<Stores />}
                                            />
                                        </Route>
                                        <Route path="/" element={<Layout />}>
                                            <Route
                                                path="/smart-tags"
                                                element={<SmartTags />}
                                            />
                                        </Route>
                                        <Route path="/" element={<Layout />}>
                                            <Route
                                                path="/detacher-units"
                                                element={<DetacherUnits />}
                                            />
                                        </Route>
                                        <Route path="/" element={<Layout />}>
                                            <Route
                                                path="/users"
                                                element={<Users />}
                                            />
                                        </Route>
                                        <Route path="/" element={<Layout />}>
                                            <Route
                                                path="/wifi-configs"
                                                element={<Wifi />}
                                            />
                                        </Route>
                                    </Route>
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </StoreProvider>
                            <Toaster />
                        </ReactQueryProvider>
                    </NotificationProvider>
                </Suspense>
            </SessionProvider>
        </div>
    );
}

export default App;

import {
    SMART_SENSORS_OBJECT_KEYS,
    SMART_SENSORS_TYPES
} from '@/hooks/data/Constants';
import { serviceAPI } from '@/utils';

type ParamType = {
    startDate: string;
    endDate: string;
    storeId: string;
    accessToken: string;
};

export async function fetchSensorUsageSystemsData({
    startDate,
    endDate,
    storeId,
    accessToken
}: ParamType) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    const res = await serviceAPI('analytic').get(
        `/sensorUsageSystems?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }
    const resData = await res.data;
    const values = [
        {
            name: 'Fashion',
            data: [
                resData.Apps.Fashion_Sensors,
                resData.CCO.Fashion_Sensors,
                resData.SCO.Fashion_Sensors,
                resData.Scan_And_Go.Fashion_Sensors,
                resData.Service_Desk.Fashion_Sensors
            ]
        },
        {
            name: 'Bottle',
            data: [
                resData.Apps.Bottle_Sensors,
                resData.CCO.Bottle_Sensors,
                resData.SCO.Bottle_Sensors,
                resData.Scan_And_Go.Bottle_Sensors,
                resData.Service_Desk.Bottle_Sensors
            ]
        },

        {
            name: 'Safer',
            data: [
                resData.Apps.Safer_Sensors,
                resData.CCO.Safer_Sensors,
                resData.SCO.Safer_Sensors,
                resData.Scan_And_Go.Safer_Sensors,
                resData.Service_Desk.Safer_Sensors
            ]
        },
        {
            name: 'Mini Safer',
            data: [
                resData.Apps.Mini_Safer_Sensors,
                resData.CCO.Mini_Safer_Sensors,
                resData.SCO.Mini_Safer_Sensors,
                resData.Scan_And_Go.Mini_Safer_Sensors,
                resData.Service_Desk.Mini_Safer_Sensors
            ]
        },
        {
            name: 'Spider',
            data: [
                resData.Apps.Spider_Sensors,
                resData.CCO.Spider_Sensors,
                resData.SCO.Spider_Sensors,
                resData.Scan_And_Go.Spider_Sensors,
                resData.Service_Desk.Spider_Sensors
            ]
        },
        {
            name: 'Others',
            data: [
                resData.Apps.Other_Sensors,
                resData.CCO.Other_Sensors,
                resData.SCO.Other_Sensors,
                resData.Scan_And_Go.Other_Sensors,
                resData.Service_Desk.Other_Sensors
            ]
        }
    ];

    return { values, ...resData };
}

export async function fetchSensorTypeUsageCashiersData({
    startDate,
    endDate,
    storeId,
    accessToken
}: ParamType) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    const res = await serviceAPI('analytic').get(
        `/usedSensorTypesCashiers?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }
    const resData = await res.data;

    const cashierNames = Object.keys(resData.Cashier);

    const values = [];
    for (let i = 0; i < SMART_SENSORS_TYPES.length; i++) {
        const name = SMART_SENSORS_TYPES[i];
        const data = [];
        for (let j = 0; j < cashierNames.length; j++) {
            const cashier = cashierNames[j];
            data.push(resData.Cashier[cashier][SMART_SENSORS_OBJECT_KEYS[i]]);
        }
        values.push({ name, data });
    }

    return { values, cashierNames, ...resData };
}

export async function fetchSensorUsageCashiersData({
    startDate,
    endDate,
    sensorType,
    storeId,
    accessToken
}: ParamType & { sensorType: string }) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    const res = await serviceAPI('analytic').get(
        `/usedSensorsCashiers?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}&sensor_type=${sensorType}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }
    const resData = await res.data;

    const cashierArray = Object.keys(resData.Cashier);
    const data = [];
    for (let i = 0; i < cashierArray.length; i++) {
        const val = resData.Cashier[cashierArray[i]].Total_Sensor_Openings;
        data.push(val);
    }
    const values = [{ data }];
    return { values, cashierArray, ...resData };
}

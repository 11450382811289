import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

// Dynamically import react-apexcharts without server-side rendering

interface ChartProps {
  type: "line" | "bar" | "pie" | "donut" | "area" | "radar"; // Specify available chart types
  data: number[] | ApexAxisChartSeries | ApexNonAxisChartSeries; // Data type based on the chart type
  // xaxis?: string[]; // Optional x-axis categories (for certain charts)
  options?: ApexOptions; // Optional custom ApexCharts options
}

const CustomChart: React.FC<ChartProps> = ({ data, options, type }) => {
  return (
    <Chart
      type={type}
      options={options}
      series={data}
      height={340}
      // width={500}
      className="w-full"
    />
  );
};

export default CustomChart;

import {
    AlertDialog as AlertDialogContainer,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { useTranslation } from 'react-i18next';

function AlertDialog({
    open,
    setOpen,
    title,
    description,
    action
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    description: string;
    action: () => void;
}) {
    const { t } = useTranslation();
    return (
        <AlertDialogContainer open={open} onOpenChange={setOpen}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {description}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                    <AlertDialogAction onClick={action}>
                        {t('common.continue')}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogContainer>
    );
}

export default AlertDialog;

import { Chart } from "@/components";
import getChartOptions from "@/components/chart/chartOptions";
import { useSensorUsageSystemsData } from "@/hooks";
// import { fetchSensorUsageSystemsData } from "@/app/lib/data/analytics/checkoutData";
import { CHECKOUT_TYPES } from "@/hooks/data/Constants";
import { useState } from "react";
import { DatePickerWithPresets } from "@/components/ui/DatePicker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";
import { useStoreContext } from "@/contexts";

function SmartTagsUsageOnPosAndAppsChart() {
  const { selectedStoreId } = useStoreContext();
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  const { data, error, isLoading } = useSensorUsageSystemsData({
    startDate: date?.from?.toISOString().split(".")[0] || "",
    endDate: date?.to?.toISOString().split(".")[0] || "",
    storeId: selectedStoreId.toString() || "",
  });

  console.log("data", data);

  if (isLoading || !data) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return (
    <div className="relative pt-2 mt-6 ">
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-3 mb-3 absolute top-[-12px] left-4 right-10 w-auto  md:w-full">
        <DatePickerWithPresets dateRange={date} setDateRange={setDate} />
      </div>
      <Chart
        type={"bar"}
        data={data.values || []}
        options={getChartOptions(CHECKOUT_TYPES, "bar", undefined, true)}
      />
    </div>
  );
}

export default SmartTagsUsageOnPosAndAppsChart;

import * as React from "react";
import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";

const presetItems = [
  { label: "last7Days", value: "7" },
  { label: "last30Days", value: "30" },
  { label: "last90Days", value: "90" },
  { label: "last365Days", value: "365" },
];

type DatePickerWithPresetsProps = {
  dateRange: DateRange | undefined;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
};

export function DatePickerWithPresets({
  dateRange,
  setDateRange,
}: DatePickerWithPresetsProps) {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal bg-white",
            !dateRange && "text-muted-foreground"
          )}
        >
          <CalendarIcon />
          {dateRange?.from && dateRange?.to ? (
            `${format(dateRange.from, "PP")} - ${format(dateRange.to, "PP")}`
          ) : (
            <span>{t("datePicker.pickADate")}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-auto flex-col space-y-2 p-2">
        <Select
          onValueChange={(value) =>
            setDateRange({
              from: addDays(new Date(), parseInt(value) * -1),
              to: new Date(),
            })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder={t("datePicker.selectADateRange")} />
          </SelectTrigger>
          <SelectContent position="popper">
            {presetItems.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                {t(`datePicker.${item.label}`)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="rounded-md border">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDateRange}
            numberOfMonths={2}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}

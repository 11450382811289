import { Slider } from '@/components/ui/slider';

function SliderWithLabel({
    label,
    onValueChange,
    min,
    max,
    step,
    disabled,
    className,
    defaultValue,
    unit
}: {
    label?: string;
    defaultValue?: number;
    onValueChange: (value: number) => void;
    min?: number;
    max?: number;
    step?: number;
    disabled?: boolean;
    className?: string;
    unit?: string;
}) {
    return (
        <div className="flex flex-col ">
            <div className="flex justify-between items-center mb-1">
                <label className="text-[11px] text-neutral-600 ">{label}</label>
                <div className="h-2 bg-primary-200 text-primary-600 rounded-md px-1 py-3 flex justify-center items-center min-w-9">
                    <span className="text-xs font-medium text-primary-600  ">
                        {defaultValue} {unit}
                    </span>
                </div>
            </div>

            <Slider
                defaultValue={[defaultValue || 0]}
                onValueChange={(value) => onValueChange(value[0])}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
                className={className}
            />
        </div>
    );
}

export default SliderWithLabel;

import React from "react";
import clsx from "clsx";

function CopyRight({
  version,
  mode = "dark",
}: {
  version: string;
  mode?: "dark" | "light";
}) {
  return (
    <div className=" bottom-5 min-w-[240px]">
      <h6
        className={clsx("text-[12px] leading-7 text-center", {
          "text-primary-50": mode === "light",
          "text-neutral-600": mode === "dark",
        })}
      >
        © Rapitag GmbH | v{version}
      </h6>
    </div>
  );
}

export default CopyRight;

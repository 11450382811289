import { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import {
    AuthContextType,
    AccessToken,
    AuthContextStoreType,
    UserType
} from '@/types';
import { checkTokensAndRefreshIfNeeded, signIn, signOut } from './authHandlers';
import { useLocalStorage, useSessionStorage } from '@/hooks';

const defaultAuthContext: AuthContextType = {
    signIn: () => undefined,
    signOut: () => undefined,
    // checkTokensAndRefreshIfNeeded: () => undefined,
    user: undefined,
    session: undefined
};

const defaultAuthContextStore: AuthContextStoreType = undefined;

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const useSession = (): AuthContextType & { loading: boolean } => {
    const context = useContext(AuthContext);
    const [local_storage_access_token, setLocalStorageAccessToken] =
        useLocalStorage('access_token', '');
    const [local_storage_arefresh_token, setLocalStorageRefreshToken] =
        useLocalStorage('refresh_token', '');
    const [rememberMe, setRememberMe] = useLocalStorage('remember_me', '');

    const [session_storage_access_token, setSessionAccessToken] =
        useSessionStorage('access_token', '');
    const [session_storage_refresh_token, setSessionRefreshToken] =
        useSessionStorage('refresh_token', '');
    const [loading, setLoading] = useState(true);

    const currentAccessToken: string = session_storage_access_token
        ? session_storage_access_token
        : local_storage_access_token;
    const currentRefreshToken: string = session_storage_refresh_token
        ? session_storage_refresh_token
        : local_storage_arefresh_token;

    useEffect(() => {
        // console.log('Checking tokens and refreshing if needed');

        if (currentAccessToken && currentRefreshToken) {
            const decoded = jwtDecode(currentAccessToken) as AccessToken;
            // console.log('decoded', decoded);

            if (decoded.exp) {
                const isTokenExpired = decoded.exp * 1000 < Date.now();
                // console.log('decoded', isTokenExpired);

                if (!isTokenExpired) {
                    context.session = {
                        access_token: currentAccessToken,
                        refresh_token: currentRefreshToken,
                        expires_in: decoded.exp
                    };
                } else {
                    console.log('Token expired');
                    refreshToken();
                    // Handle token expiration, possibly clear session or refresh token
                    // context.session = undefined; // This ensures expired tokens are cleared
                    // checkTokensAndRefreshIfNeeded(
                    //   currentAccessToken,
                    //   currentRefreshToken,
                    //   context
                    // );
                }
            }
        }
        setLoading(false);
    }, [currentAccessToken, currentRefreshToken, context]);

    const refreshToken = async () => {
        const response = await checkTokensAndRefreshIfNeeded(context);
        if (response) {
            const { access_token, refresh_token } = response;
            context.session = {
                access_token,
                refresh_token,
                expires_in: jwtDecode(access_token).exp!
            };
            if (rememberMe) {
                setLocalStorageAccessToken(access_token);
                setLocalStorageRefreshToken(refresh_token);
            }
            setSessionAccessToken(access_token);
            setSessionRefreshToken(refresh_token);
        }
    };

    return { ...context, loading };
};

export const SessionProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [session, setSession] = useState<AuthContextStoreType>(
        defaultAuthContextStore
    );
    const [, setAccessToken] = useLocalStorage('access_token', '');
    const [, setRefreshToken] = useLocalStorage('refresh_token', '');
    const [, setRememberMe] = useLocalStorage('remember_me', '');
    const [, setMerchantId] = useLocalStorage('merchant_id', '');
    const [user, setUser] = useState<UserType | undefined>(undefined);

    const [session_access_token, setSessionAccessToken] = useSessionStorage(
        'access_token',
        ''
    );
    const [session_refresh_token, setSessionRefreshToken] = useSessionStorage(
        'refresh_token',
        ''
    );

    // Helper function to decode the user from the access_token
    const getUser = (accessToken: string): UserType | undefined => {
        if (accessToken) {
            const decoded = jwtDecode<AccessToken>(accessToken);
            setMerchantId(decoded.merchant_id);
            return {
                name: decoded.name,
                storeId: decoded.store_id,
                merchantId: decoded.merchant_id
            };
        }
        return undefined;
    };

    // Update user whenever session changes
    useEffect(() => {
        if (session?.access_token) {
            const userData = getUser(session.access_token);
            setUser(userData);
        }
    }, [session]);

    // Check session storage or local storage for access token on initial load
    useEffect(() => {
        const storedAccessToken =
            session_access_token || localStorage.getItem('access_token');
        if (storedAccessToken) {
            setSession({
                access_token: storedAccessToken,
                refresh_token: session_refresh_token,
                expires_in: jwtDecode(storedAccessToken).exp!
            });
            const userData = getUser(storedAccessToken);
            setUser(userData); // Set user if token is present
        }
    }, [session_access_token]);

    return (
        <AuthContext.Provider
            value={{
                signIn: async (
                    email: string,
                    password: string,
                    rememberMe: boolean
                ) => {
                    const data = await signIn(email, password);

                    if (rememberMe) {
                        setAccessToken(data.access_token);
                        setRefreshToken(data.refresh_token);
                        setRememberMe(JSON.stringify(true));
                    } else {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('remember_me');
                    }

                    setSessionAccessToken(data.access_token);
                    setSessionRefreshToken(data.refresh_token);

                    setSession({
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expires_in: jwtDecode(data.access_token).exp!
                    });
                },
                signOut: async () => {
                    await signOut(session_access_token, session_refresh_token);
                    setSession(defaultAuthContextStore);
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('remember_me');
                    sessionStorage.removeItem('access_token');
                    sessionStorage.removeItem('refresh_token');
                    setUser(undefined); // Clear user on sign out
                },
                user,
                session // Provide session through the context
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

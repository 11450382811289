import { useQuery } from "react-query";
import { fetchTotalSensorsData } from "@/services";
import { useSession } from "@/contexts";

type QueryParams = {
  startDate: string;
  endDate: string;
  storeId: string;
};

export function useTotalSensorsData(params: QueryParams) {
  const { session, user } = useSession();
  const { startDate, endDate, storeId } = params;

  if (!user) {
    throw new Error("No user found");
  }
  if (!session) {
    throw new Error("No session found");
  }

  const accessToken = session?.access_token;
  return useQuery(
    ["sensorUsageSystemsData", startDate, endDate, storeId], // Unique query key
    () => fetchTotalSensorsData({ startDate, endDate, storeId, accessToken }),
    {
      enabled: !!accessToken, // Only run if accessToken is available
      retry: 2, // Optional: retry up to 2 times if the request fails
      staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
      cacheTime: 10 * 60 * 1000, // Optional: cache the data for 10 minutes
      onError: (err) => {
        console.log("Error fetching data", err);
        // Optionally, setError((err as Error).message) if you have a state for errors
      },
    }
  );
}

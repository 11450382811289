import { ThemeType } from "@/types";

function setTheme(theme: ThemeType) {
  document.documentElement.style.setProperty(
    "--primary-default",
    theme.primary_default
  );
  document.documentElement.style.setProperty("--primary-50", theme.primary_50);
  document.documentElement.style.setProperty(
    "--primary-200",
    theme.primary_200
  );
  document.documentElement.style.setProperty(
    "--primary-400",
    theme.primary_400
  );
  document.documentElement.style.setProperty(
    "--primary-500",
    theme.primary_500
  );
  document.documentElement.style.setProperty(
    "--primary-600",
    theme.primary_600
  );
  document.documentElement.style.setProperty(
    "--primary-700",
    theme.primary_700
  );
  document.documentElement.style.setProperty(
    "--primary-800",
    theme.primary_800
  );
  document.documentElement.style.setProperty(
    "--background-default",
    theme.background_default
  );
}

export default setTheme;

import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { FloatingLabelInput } from '@/components/ui/floatingLabelInput';
import { ComboBoxResponsive } from '../ui/combobox';
import { useEffect, useState } from 'react';
import { UpdateWiFiInputType, WifiConfig, WifiSecurityType } from '@/types';
import { useStoreContext } from '@/contexts';
import { WIFI_SECURITY_TYPES } from '@/hooks/data/Constants';
import { MultiSelect, FormModal } from '@/components';
import { useWifi } from '@/hooks';

const validWifiConfigTypes = WIFI_SECURITY_TYPES.map((type) => type.value) as [
    string,
    ...string[]
];

const getFormSchema = (updateMode: boolean) =>
    z
        .object({
            wifiConfigName: z.string().min(2).max(50),
            ssid: z.string().min(2).max(50),
            password: z.string().max(50).optional(), // Initially optional
            wifiConfigType: z.enum(validWifiConfigTypes),
            storeIds: z.array(z.string()).optional(),
            identity: z.string().max(50).optional(),
            securityType: z.string().max(50).optional(),
            securityKey: z.string().max(50).optional()
        })
        .refine(
            (data) =>
                data.wifiConfigType !== 'WPA2 Enterprise' ||
                (data.identity && data.securityType && data.securityKey),
            {
                message:
                    'Identity, Security Type, and Security Key are required for WPA2 Enterprise.',
                path: ['wifiConfigType']
            }
        )
        .refine(
            (data) =>
                updateMode || // Skip validation in update mode
                (data.wifiConfigType !== 'WPA2 Enterprise' &&
                    data.wifiConfigType !== 'WPA2 Personal') ||
                data.password, // Require password only in create mode
            {
                message:
                    'Password is required for WPA2 Personal or WPA2 Enterprise.',
                path: ['password']
            }
        );

function WifiForm({
    data,
    openForm,
    setOpenForm
}: {
    data?: WifiConfig | undefined;
    openForm: boolean;
    setOpenForm: (open: boolean) => void;
}) {
    const { t } = useTranslation();
    const [transformedStoresList, setTransformedStoresList] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);
    const { createWifiConfig, updateWifiConfig } = useWifi();
    const { stores, isLoading } = useStoreContext();
    const formSchema = getFormSchema(!!data);

    useEffect(() => {
        if (stores && stores.length > 0) {
            const data = stores?.map((store) => ({
                value: store?.storeId?.toString(),
                label: store?.storeName
            }));
            if (data) {
                setTransformedStoresList(data);
            }
        }
    }, [stores]);

    // Set form values when the form is opened with data (update mode)
    useEffect(() => {
        if (data) {
            const numberStoreIds = data.storeIds.map((id) => id.toString());
            const parsedData = JSON.parse(data.wifiConfigJson);
            form.setValue('wifiConfigName', data?.wifiConfigName);
            form.setValue('wifiConfigType', data.wifiConfigType as string);
            form.setValue('storeIds', numberStoreIds);
            form.setValue('ssid', parsedData.ssid);
            form.setValue('password', '');
            form.setValue('identity', parsedData.identity);
            form.setValue('securityType', parsedData.security_type);
            form.setValue('securityKey', parsedData.security_key);
        }
    }, [data]);

    // Reset form when the form is closed
    useEffect(() => {
        if (!openForm) {
            form.reset();
        }
    }, [openForm]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            wifiConfigName: '',
            ssid: '',
            password: '',
            wifiConfigType: 'Public WiFi Configuration',
            storeIds: [],
            identity: '',
            securityType: '',
            securityKey: ''
        }
    });

    const wifiConfigTypeValue = form.watch('wifiConfigType');

    function onSubmit(values: z.infer<typeof formSchema>) {
        let wifiConfigs = {};
        if (values.wifiConfigType === 'Public WiFi Configuration') {
            wifiConfigs = {
                ssid: values.ssid
            };
        }
        if (values.wifiConfigType === 'WPA2 Personal') {
            wifiConfigs = {
                ssid: values.ssid,
                password: values.password
            };
        }
        if (values.wifiConfigType === 'WPA2 Enterprise') {
            wifiConfigs = {
                ssid: values.ssid,
                password: values.password,
                identity: values.identity,
                // security_type: values.securityType,
                security_type: 'security_type',
                security_key: values.securityKey
            };
        }

        const wifiConfigObject = {
            wifiConfigName: values.wifiConfigName,
            wifiConfigType: values.wifiConfigType as WifiSecurityType,
            storeIds: values.storeIds?.map((id) => Number(id)) || [],
            wifiConfigJson: wifiConfigs
        };

        if (data) {
            // Check if the store data has been updated (no changes detected)
            const isUpdated = Object.keys(wifiConfigObject).some(
                (key) =>
                    wifiConfigObject[key as keyof typeof wifiConfigObject] !==
                    data[key as keyof UpdateWiFiInputType]
            );
            if (!isUpdated) {
                setOpenForm(false);
                console.log('No changes detected');
                return;
            }

            updateWifiConfig.mutate(
                {
                    ...wifiConfigObject,
                    id: data.id,
                    wifiConfigJson: JSON.stringify(
                        wifiConfigObject.wifiConfigJson
                    )
                },
                {
                    onSuccess: () => {
                        setOpenForm(false);
                    }
                }
            );
        } else {
            createWifiConfig.mutate(wifiConfigObject, {
                onSuccess: () => {
                    setOpenForm(false);
                }
            });
        }
    }

    return (
        <FormModal
            open={openForm}
            setOpen={setOpenForm}
            btnLabel={
                !!data ? t('wifi.editWifiConfig') : t('wifi.newWifiConfig')
            }
        >
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                >
                    <FormField
                        control={form.control}
                        name="wifiConfigName"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="wifiConfigName"
                                    label={t('wifi.configName') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="ssid"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="ssid"
                                    label={t('wifi.ssid') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="wifiConfigType"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={WIFI_SECURITY_TYPES}
                                    showPrefixIcon={false}
                                    placeholder={t('stores.selectState')}
                                    label={t('wifi.securityType') + ' *'}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    {(wifiConfigTypeValue === 'WPA2 Personal' ||
                        wifiConfigTypeValue === 'WPA2 Enterprise') && (
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FloatingLabelInput
                                        {...field}
                                        id="password"
                                        label={t('wifi.password') + ' *'}
                                        type="password"
                                        required={!data}
                                    />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    )}
                    {wifiConfigTypeValue === 'WPA2 Enterprise' && (
                        <>
                            <FormField
                                control={form.control}
                                name="identity"
                                render={({ field }) => (
                                    <FormItem>
                                        <FloatingLabelInput
                                            {...field}
                                            id="identity"
                                            label={t('wifi.identity') + ' *'}
                                            type="text"
                                            required
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {/* <FormField
                                control={form.control}
                                name="securityType"
                                render={({ field }) => (
                                    <FormItem>
                                        <FloatingLabelInput
                                            {...field}
                                            id="securityType"
                                            label={
                                                t('wifi.securityType') + ' *'
                                            }
                                            type="text"
                                            required
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> */}
                            <FormField
                                control={form.control}
                                name="securityKey"
                                render={({ field }) => (
                                    <FormItem>
                                        <FloatingLabelInput
                                            {...field}
                                            id="securityKey"
                                            label={t('wifi.securityKey') + ' *'}
                                            type="text"
                                            required
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </>
                    )}
                    <FormField
                        control={form.control}
                        name="storeIds"
                        render={({ field }) => (
                            <FormItem>
                                <MultiSelect
                                    options={transformedStoresList}
                                    defaultValue={field.value}
                                    onValueChange={field.onChange}
                                    label={t('wifi.stores')}
                                    placeholder={t('common.selectStore')}
                                    variant="inverted"
                                    animation={0}
                                    maxCount={3}
                                    className=" min-h-12 w-full border-gray-300 focus:border-primary-500"
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <Button
                        disabled={!!data && !form.formState.isDirty}
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Form>
        </FormModal>
    );
}

export default WifiForm;

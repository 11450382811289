import { serviceAPI } from '@/utils';

// Total number of sensors
type ParamType = {
    accessToken: string;
    store_id: number;
};
export async function fetchOverviewData({ accessToken, store_id }: ParamType) {
    if (!accessToken) {
        throw new Error('No session or access token found');
    }

    // Fetch data using the accessToken
    const res = await serviceAPI('analytic').get(
        `/analyticsStatistics?store_id=${store_id}`
    );

    if (res.status !== 200) {
        throw new Error('Failed to fetch sensor data');
    }

    const overviewData = res.data;
    return overviewData;
}

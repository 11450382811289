declare global {
  interface Window {
    env: any;
  }
}

interface Config {
  REACT_APP_BASE_PATH: string;
  REACT_APP_BACKEND_USERS_URL: string;
  REACT_APP_BACKEND_ANALYTICS_URL: string;
  REACT_APP_BACKEND_DEVICES_URL: string;
  REACT_APP_AUTH_AUTHORITY: string;
  REACT_APP_AUTH_CLIENT_ID: string;
  REACT_APP_LOGOUT_URL: string;
  ENV: string;
  DEBUG: boolean;
  VERSION: number;
}

const config: Config = {
  REACT_APP_BASE_PATH: window.env.REACT_APP_BASE_PATH,
  REACT_APP_BACKEND_USERS_URL: window.env.REACT_APP_BACKEND_USERS_URL,
  REACT_APP_BACKEND_ANALYTICS_URL: window.env.REACT_APP_BACKEND_ANALYTICS_URL,
  REACT_APP_BACKEND_DEVICES_URL: window.env.REACT_APP_BACKEND_DEVICES_URL,
  REACT_APP_AUTH_AUTHORITY: window.env.REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_CLIENT_ID: window.env.REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_LOGOUT_URL: window.env.REACT_APP_LOGOUT_URL,
  ENV: window.env.ENV,
  DEBUG: window.env.DEBUG,
  VERSION: window.env.VERSION,
};

export default config;

import { useTranslation } from "react-i18next";
import {
  NumberOfSmartTagsChart,
  SmartTagsInUseChart,
  AssignedVsUsedSensorChart,
  NumberOfSmartTagsHistoricalChart,
  SmartTagsInUseHistoricalChart,
  AssignedVsUsedSensorHistoricalChart,
} from "./charts";

function InventoryTab() {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white rounded-xl mb-6 py-4">
        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
          {t("analytics.numberOfSensors")}
        </h1>
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 px-2 ">
          <NumberOfSmartTagsChart />
          <NumberOfSmartTagsHistoricalChart />
        </div>
      </div>

      <div className="bg-white rounded-xl mb-5 py-4 px-2">
        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
          {t("analytics.sensorsInUse")}
        </h1>
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 ">
          <SmartTagsInUseChart />
          <SmartTagsInUseHistoricalChart />
        </div>
      </div>

      <div className="bg-white rounded-xl mb-5 py-4 px-2">
        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 my-2">
          {t("analytics.assignedVsUsed")}
        </h1>
        <div className="grid  sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 ">
          <AssignedVsUsedSensorChart />
          <AssignedVsUsedSensorHistoricalChart />
        </div>
      </div>
    </>
  );
}

export default InventoryTab;

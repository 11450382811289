import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useSession, useStoreContext } from "@/contexts";
import AppSidebar from "./Sidebar";

import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import { StoreType } from "@/types";

function Layout() {
  const { user } = useSession();
  const { stores, isLoading } = useStoreContext();
  const merchantId = user?.merchantId ? user.merchantId : "0";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <SidebarProvider>
        <AppSidebar merchantId={merchantId} stores={stores as StoreType[]} />
        <SidebarInset>
          <Header merchantId={merchantId} userName={user?.name || ""} />
          <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
            <Outlet />
          </div>
        </SidebarInset>
      </SidebarProvider>
    </>
  );
}

export default Layout;

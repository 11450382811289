import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { StoreType, CreateAndEditStoreInputType } from '@/types';
import { useSession } from '../authContext/AuthContext';
import Configs from '@/utils/configs';
import { useGlobalNotification } from '../ErrorContext';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

interface StoresContextProps {
    stores: StoreType[] | undefined;
    isLoading: boolean;
    isFetchingError: boolean;
    errorMessage: string | null;
    createStore: (
        newStore: Omit<CreateAndEditStoreInputType, 'storeId'>
    ) => Promise<void>;
    updateStore: (updatedStore: CreateAndEditStoreInputType) => Promise<void>;
    deleteStore: (id: string) => Promise<void>;
    selectedStoreId: string;
    setSelectedStoreId: (storeId: string) => void;
}

const StoreContext = createContext<StoresContextProps | undefined>(undefined);

const useStoreQuery = (
    merchantId: string,
    access_token: string,
    setSelectedStoreId: (id: string) => void,
    selectedStoreId: string,
    setErrorMessage: (message: string) => void
) => {
    return useQuery<StoreType[], Error>(
        ['stores', merchantId],
        async () => {
            const response = await serviceAPI('device').get(`/stores`);

            if (response.status !== 200) {
                throw new Error('Failed to fetch stores');
            }

            return response.data;
        },
        {
            enabled: !!access_token,
            staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
            onSuccess: (data) => {
                // Automatically set the first store as the default selectedStore
                if (!selectedStoreId && data.length > 0) {
                    setSelectedStoreId(data[0].storeId);
                }
            },
            onError: (error: any) => {
                setErrorMessage(
                    error.response?.data?.message || 'Failed to fetch stores'
                );
            }
        }
    );
};

export const StoreProvider: React.FC<{ children: ReactNode }> = ({
    children
}) => {
    const queryClient = useQueryClient();
    const { session, user } = useSession();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedStoreId, setSelectedStoreId] = useState<string>('');
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { t } = useTranslation();

    // Fetch stores
    const {
        data: stores,
        isLoading,
        isError: isFetchingError
    } = useStoreQuery(
        user?.merchantId!,
        session?.access_token!,
        setSelectedStoreId,
        selectedStoreId,
        setErrorMessage
    );

    // Create store
    const createStoreMutation = useMutation(
        async (newStore: Omit<CreateAndEditStoreInputType, 'storeId'>) => {
            await serviceAPI('device').post(`/stores`, newStore);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stores']);
                setGlobalSuccess(t('store.storeCreateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error || 'Failed to create store'
                );
                setErrorMessage(
                    error.response?.data?.error || 'Failed to create store'
                );
            }
        }
    );

    // Update store
    const updateStoreMutation = useMutation(
        async (updatedStore: CreateAndEditStoreInputType) => {
            await serviceAPI('device').put(
                `/stores/${updatedStore.storeId}`,
                updatedStore
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stores']);
                setGlobalSuccess(t('store.storeUpdateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error || 'Failed to update store'
                );
                setErrorMessage(
                    error.response?.data?.error || 'Failed to update store'
                );
            }
        }
    );

    // Delete store
    const deleteStoreMutation = useMutation(
        async (id: string) => {
            await serviceAPI('device').delete(`/stores/${id}`);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stores']);
                setGlobalSuccess(t('store.storeDeleteSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error || 'Failed to update store'
                );
                setErrorMessage(
                    error.response?.data?.error || 'Failed to delete store'
                );
            }
        }
    );

    const createStore = async (
        newStore: Omit<CreateAndEditStoreInputType, 'storeId'>
    ): Promise<void> => {
        setErrorMessage(null);
        try {
            await createStoreMutation.mutateAsync(newStore);
        } catch (error: any) {
            // Error already handled in mutation
            // Error already handled in mutation
            setErrorMessage(
                error.response?.data?.message || 'Failed to create store'
            );
            throw error; // Re-throw the error so the caller can handle it
        }
    };
    const updateStore = async (
        updatedStore: CreateAndEditStoreInputType
    ): Promise<void> => {
        setErrorMessage(null);
        try {
            await updateStoreMutation.mutateAsync(updatedStore);
        } catch (error: any) {
            // Error already handled in mutation
            setErrorMessage(
                error.response?.data?.message || 'Failed to update store'
            );
            throw error; // Re-throw the error so the caller can handle it
        }
    };

    const deleteStore = async (id: string): Promise<void> => {
        setErrorMessage(null);
        try {
            await deleteStoreMutation.mutateAsync(id);
        } catch (error) {
            // Error already handled in mutation
        }
    };

    return (
        <StoreContext.Provider
            value={{
                stores,
                isLoading,
                isFetchingError,
                errorMessage,
                createStore,
                updateStore,
                deleteStore,
                selectedStoreId,
                setSelectedStoreId
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useStoreContext = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStoreContext must be used within a StoreProvider');
    }
    return context;
};

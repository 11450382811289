export const VERSION_NUMBER: string = '0.0.16';

export const SMART_SENSORS_TYPES = [
    'Fashion',
    'Bottle',
    'Safer',
    'Mini Safer',
    'Spider',
    'Others'
];

export const SMART_SENSORS_OBJECT_KEYS = [
    'Fashion_Sensors',
    'Bottle_Sensors',
    'Safer_Sensors',
    'Mini_Safer_Sensors',
    'Spider_Sensors',
    'Other_Sensors'
];

export const CHECKOUT_TYPES = [
    'APPs',
    'CCO',
    'SCO',
    'Scan & Go',
    'Service Desk'
];

export const DATE_RANGES = [
    { label: 'Last 24 hours', value: '1' },
    { label: 'Last 7 days', value: '7' },
    { label: 'Last 30 days', value: '30' },
    { label: 'Last 90 days', value: '90' },
    { label: 'Last 180 days', value: '180' },
    { label: 'Last 365 days', value: '365' }
];

export const SMART_TAGS_ITEMS = [
    { label: 'Fashion', value: 'fashion' },
    { label: 'Bottle', value: 'bottle' },
    { label: 'Safer', value: 'safer' },
    { label: 'Mini Safer', value: 'mini_safer' },
    { label: 'Spider', value: 'spider' },
    { label: 'Others', value: 'others' }
];

export const SMART_TAGS_STATUS = ['Assigned', 'Used'];
export const WIFI_SECURITY_TYPES = [
    { label: 'Public WiFi', value: 'Public WiFi Configuration' },
    { label: 'WPA2 Personal', value: 'WPA2 Personal' },
    { label: 'WPA2 Enterprise', value: 'WPA2 Enterprise' }
];

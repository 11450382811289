import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { FloatingLabelInput } from '@/components/ui/floatingLabelInput';
import { ComboBoxResponsive } from '../ui/combobox';
import { useEffect, useState } from 'react';
import { DetacherUnitType, DetacherUnitUpdateType } from '@/types';
import { useStoreContext } from '@/contexts';
import { MultiSelect, FormModal, SliderWithLabel } from '@/components';
import { useDetacherUnit } from '@/hooks';
import { ToggleGroup, ToggleGroupItem } from '@radix-ui/react-toggle-group';
import { WifiHigh, WifiLow, Wifi } from 'lucide-react';

const formSchema = z.object({
    description: z.string().min(2).max(50),
    storeId: z.string(),
    // applianceType: z.string().min(2).max(50),
    applianceType: z.string().optional(),

    rssiLimit: z.number(),
    buttonActiveSeconds: z.number(),
    automaticOpening: z.boolean()
});

function DetacherUnitForm({
    data,
    openForm,
    setOpenForm
}: {
    data?: DetacherUnitType | undefined;
    openForm: boolean;
    setOpenForm: (open: boolean) => void;
}) {
    const { t } = useTranslation();
    const [transformedStoresList, setTransformedStoresList] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);
    // const [transformedZoneList, setTransformedZoneList] = useState<
    //     {
    //         value: string;
    //         label: string;
    //     }[]
    // >([]);
    const { updateDetacherUnit } = useDetacherUnit();
    const { stores, isLoading } = useStoreContext();

    useEffect(() => {
        if (stores && stores.length > 0) {
            const data = stores?.map((store) => ({
                value: store?.storeId?.toString(),
                label: store?.storeName
            }));
            if (data) {
                setTransformedStoresList(data);
            }
        }
    }, [stores]);

    const transformedApplianceTypes =
        data?.zones && data.zones.length > 0
            ? data.zones.map((zone) => ({
                  value: zone?.id.toString(),
                  label: zone?.name
              }))
            : [];

    // Set form values when the form is opened with data (update mode)
    useEffect(() => {
        if (data) {
            const zoneName =
                transformedApplianceTypes.find(
                    (item) => item.value === data?.zoneId?.toString()
                )?.value || null;

            const rssi = setRSSI(+data.rssiLimit);
            form.setValue('description', data?.description);
            form.setValue('storeId', data.storeId.toString());
            form.setValue('rssiLimit', +rssi);
            form.setValue('applianceType', zoneName ? zoneName : undefined);
            form.setValue('buttonActiveSeconds', data.buttonActiveSeconds);
            form.setValue('automaticOpening', data.automaticOpening);
        }
    }, [data]);

    const setRSSI = (value: number) => {
        if (value <= -70) {
            return '-70';
        } else if (value <= -50) {
            return '-50';
        } else {
            return '-30';
        }
    };

    // Reset form when the form is closed
    useEffect(() => {
        if (!openForm) {
            form.reset();
        }
    }, [openForm]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            description: '',
            storeId: '0',
            applianceType: '',
            rssiLimit: -40,
            automaticOpening: true,
            buttonActiveSeconds: 10
        }
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        const detachedUnitObject = {
            ...values,
            applianceType:
                transformedApplianceTypes.find(
                    (item) => item.value === values.applianceType
                )?.label || '',
            storeId: +values.storeId,
            rssiLimit: values.rssiLimit.toString(),
            name: data?.name || ''
        };

        console.log(detachedUnitObject);

        // Check if the store data has been updated (no changes detected)
        const isUpdated = Object.keys(values).some(
            (key) =>
                values[key as keyof typeof DetacherUnitForm] !==
                data![key as keyof DetacherUnitType]
        );
        if (!isUpdated) {
            setOpenForm(false);
            console.log('No changes detected');
            return;
        }
        updateDetacherUnit.mutate(detachedUnitObject, {
            onSuccess: () => {
                setOpenForm(false);
            }
        });
    }

    return (
        <FormModal
            open={openForm}
            setOpen={setOpenForm}
            btnLabel={t('detacherUnit.editDetacherUnit')}
            hideCreateButton={true}
        >
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                >
                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="description"
                                    label={t('detacherUnit.description') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="storeId"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={transformedStoresList}
                                    showPrefixIcon={false}
                                    placeholder={t('stores.selectState')}
                                    label={t('detacherUnit.store') + ' *'}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="applianceType"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={transformedApplianceTypes}
                                    showPrefixIcon={false}
                                    placeholder={t('detacherUnit.selectZone')}
                                    label={t('detacherUnit.zone') + ' *'}
                                    // Override onChange
                                    // onChange={(value) => {
                                    //     const zoneName =
                                    //         transformedApplianceTypes.find(
                                    //             (item) => item.value === value
                                    //         )?.label || '';
                                    //     console.log(
                                    //         'Selected zoneName:',
                                    //         zoneName
                                    //     ); // Custom logic

                                    //     field.onChange(zoneName); // Call react-hook-form's onChange
                                    // }}
                                    // value={field.value} // Pass the controlled value

                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="rssiLimit"
                        render={({ field: { value, onChange } }) => (
                            <FormItem>
                                <label className="text-[11px] text-neutral-600 ">
                                    {t('detacherUnit.sensitivity')}
                                </label>
                                <ToggleGroup
                                    type="single"
                                    value={value.toString()} // Bind to form state
                                    onValueChange={(value) => onChange(+value)} // Update form state
                                    className="flex gap-2 w-full"
                                >
                                    <ToggleGroupItem
                                        value="-70"
                                        className={`px-4 py-2 border rounded-md focus:outline-none flex flex-col justify-center items-center flex-grow  ${
                                            value.toString() === '-70'
                                                ? 'bg-primary-400 text-white' // Active styles
                                                : 'bg-background text-neutral-600' // Inactive styles
                                        }`}
                                    >
                                        <Wifi className="h-5 w-5" />
                                        <span className="text-xs">
                                            {t('detacherUnit.high')}
                                        </span>
                                    </ToggleGroupItem>
                                    <ToggleGroupItem
                                        value="-50"
                                        className={`px-4 py-2 border rounded-md focus:outline-none flex flex-col justify-center items-center flex-grow  ${
                                            value.toString() === '-50'
                                                ? 'bg-primary-400 text-white' // Active styles
                                                : 'bg-background text-neutral-600' // Inactive styles
                                        }`}
                                    >
                                        <WifiHigh className="h-5 w-5" />
                                        <span className="text-xs">
                                            {t('detacherUnit.medium')}
                                        </span>
                                    </ToggleGroupItem>
                                    <ToggleGroupItem
                                        value="-30"
                                        className={`px-4 py-2 border rounded-md focus:outline-none flex flex-col justify-center items-center  flex-grow ${
                                            value.toString() === '-30'
                                                ? 'bg-primary-400 text-white' // Active styles
                                                : 'bg-background text-neutral-600' // Inactive styles
                                        }`}
                                    >
                                        <WifiLow className="h-5 w-5" />
                                        <span className="text-xs">
                                            {t('detacherUnit.low')}
                                        </span>
                                    </ToggleGroupItem>
                                </ToggleGroup>

                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="buttonActiveSeconds"
                        render={({ field: { value, onChange } }) => (
                            <FormItem>
                                <SliderWithLabel
                                    min={1}
                                    max={60}
                                    step={1}
                                    defaultValue={value}
                                    onValueChange={onChange}
                                    className="w-full"
                                    unit="s"
                                    label={t(
                                        'detacherUnit.buttonActiveSeconds'
                                    )}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    {/* {(wifiConfigTypeValue === 'WPA2 Personal' ||
                        wifiConfigTypeValue === 'WPA2 Enterprise') && (
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FloatingLabelInput
                                        {...field}
                                        id="password"
                                        label={t('wifi.password') + ' *'}
                                        type="password"
                                        required={!data}
                                    />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    )} */}
                    {/* {wifiConfigTypeValue === 'WPA2 Enterprise' && (
                        <>
                            <FormField
                                control={form.control}
                                name="identity"
                                render={({ field }) => (
                                    <FormItem>
                                        <FloatingLabelInput
                                            {...field}
                                            id="identity"
                                            label={t('wifi.identity') + ' *'}
                                            type="text"
                                            required
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                       
                            <FormField
                                control={form.control}
                                name="securityKey"
                                render={({ field }) => (
                                    <FormItem>
                                        <FloatingLabelInput
                                            {...field}
                                            id="securityKey"
                                            label={t('wifi.securityKey') + ' *'}
                                            type="text"
                                            required
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </>
                    )} */}
                    {/* <FormField
                        control={form.control}
                        name="storeIds"
                        render={({ field }) => (
                            <FormItem>
                                <MultiSelect
                                    options={transformedStoresList}
                                    defaultValue={field.value}
                                    onValueChange={field.onChange}
                                    label={t('wifi.stores')}
                                    placeholder={t('common.selectStore')}
                                    variant="inverted"
                                    animation={0}
                                    maxCount={3}
                                    className=" min-h-12 w-full border-gray-300 focus:border-primary-500"
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    /> */}

                    <Button
                        disabled={!!data && !form.formState.isDirty}
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Form>
        </FormModal>
    );
}

export default DetacherUnitForm;

import { useState, useEffect } from 'react';

function useLocalStorage<T>(key: string, initialValue: T) {
    // Initialize state with the value from localStorage (if available) or the initial value
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = localStorage.getItem(key);
            // Check if it's valid JSON, fallback to a raw string
            return item && (item.startsWith('{') || item.startsWith('['))
                ? JSON.parse(item)
                : item || initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key "${key}":`, error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            if (storedValue === '0') {
                localStorage.removeItem(key);
            } else if (storedValue !== '') {
                // Store raw strings directly without JSON.stringify
                const valueToStore =
                    typeof storedValue === 'string'
                        ? storedValue
                        : JSON.stringify(storedValue);
                localStorage.setItem(key, valueToStore);
            }
        } catch (error) {
            console.warn(`Error setting localStorage key "${key}":`, error);
        }
    }, [key, storedValue]);

    return [storedValue, setStoredValue] as const;
}

export default useLocalStorage;

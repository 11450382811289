import * as React from 'react';

import { useMediaQuery } from '@/hooks';
import { Button } from '@/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList
} from '@/components/ui/command';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from '@/components/ui/popover';
import { Check, ChevronsUpDown, ShoppingCart } from 'lucide-react';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

type Status = {
    value: string;
    label: string;
    icon?: string;
};

export function ComboBoxResponsive({
    statuses,
    className,
    disabled,
    hideLabel,
    showPrefixIcon = false,
    onChange,
    // id,
    placeholder = 'select an item',
    value,
    label,
    maxChars = 18
}: {
    statuses: Status[];
    className?: string;
    disabled?: boolean;
    hideLabel?: boolean;
    showPrefixIcon?: boolean;
    onChange: (storeId: string | null) => void;
    // id?: string;
    placeholder?: string;
    // defaultValue?: string;
    value?: string;
    label?: string;
    maxChars?: number;
}) {
    const [open, setOpen] = React.useState(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [selectedStatus, setSelectedStatus] = React.useState<Status | null>(
        value !== undefined
            ? statuses.find((status) => status.value === value) || null
            : null
    );

    React.useEffect(() => {
        if (value !== undefined) {
            setSelectedStatus(
                statuses.find((status) => status.value === value) || null
            );
        } else {
            setSelectedStatus(null);
        }
    }, [value, statuses]);

    const selectHandler = (status: Status | null) => {
        setSelectedStatus(status);
        onChange(status?.value || null);
    };

    if (isDesktop) {
        return (
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className={`w-full justify-between ${className} `}
                        disabled={disabled}
                    >
                        <div className=" relative flex justify-start gap-2 items-center">
                            <label className=" absolute -left-1 text-sm text-gray-500  top-2 z-10 origin-[0] -translate-y-8 scale-75 bg-background px-2  ">
                                {label}
                            </label>

                            {showPrefixIcon && (
                                <ShoppingCart className="opacity-50" />
                            )}
                            {selectedStatus?.icon && `${selectedStatus.icon} `}
                            {hideLabel ? (
                                ''
                            ) : selectedStatus ? (
                                <span className="text-base md:text-sm font-normal">
                                    {selectedStatus.label?.slice(0, maxChars)}
                                    {selectedStatus.label?.length > maxChars
                                        ? '...'
                                        : ''}
                                </span>
                            ) : (
                                <span className="  text-base md:text-sm font-normal">
                                    {placeholder}
                                </span>
                            )}
                        </div>

                        {!hideLabel && (
                            <ChevronsUpDown className="opacity-50" />
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full  p-0" align="start">
                    <StatusList
                        setOpen={setOpen}
                        setSelectedStatus={(v) => selectHandler(v)}
                        statuses={statuses}
                        selectedStatus={selectedStatus}
                    />
                </PopoverContent>
            </Popover>
        );
    }

    return (
        <Drawer open={open} onOpenChange={setOpen}>
            <DrawerTrigger asChild>
                <Button
                    variant="outline"
                    className={`w-full justify-start ${className}`}
                >
                    {selectedStatus ? (
                        <>{selectedStatus.label}</>
                    ) : (
                        <>+ Set status</>
                    )}
                </Button>
            </DrawerTrigger>
            <DrawerContent>
                <div className="mt-4 border-t">
                    <StatusList
                        setOpen={setOpen}
                        setSelectedStatus={(v) => selectHandler(v)}
                        statuses={statuses}
                        selectedStatus={selectedStatus}
                    />
                </div>
            </DrawerContent>
        </Drawer>
    );
}

function StatusList({
    setOpen,
    setSelectedStatus,
    statuses,
    selectedStatus
}: {
    setOpen: (open: boolean) => void;
    setSelectedStatus: (status: Status | null) => void;
    statuses: Status[];
    selectedStatus: Status | null;
}) {
    const { t } = useTranslation();

    return (
        <Command>
            <CommandInput placeholder="Filter status..." />
            <CommandList>
                <CommandEmpty>{t('common.noResultsFound')}</CommandEmpty>
                <CommandGroup className="bg-background overflow-auto">
                    {statuses.map((status) => (
                        <CommandItem
                            key={status.value}
                            onSelect={(currentValue) => {
                                const value = statuses.find(
                                    (option) =>
                                        option.label.toLowerCase() ===
                                        currentValue.toLocaleLowerCase()
                                );
                                setSelectedStatus(value || null);
                                setOpen(false);
                            }}
                            value={status.label}
                        >
                            {status.icon && `${status.icon} `}
                            {status.label}
                            <Check
                                className={cn(
                                    'ml-auto',
                                    selectedStatus?.value === status.value
                                        ? 'opacity-100'
                                        : 'opacity-0'
                                )}
                            />
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    );
}

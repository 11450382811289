import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en";
import de from "./translations/de";

const detectionOptions = {
  order: [
    "navigator",
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "htmlTag",
    "path",
    "subdomain",
  ],
  caches: ["localStorage", "cookie"], // Cache the detected language
};

i18n
  // Add React bindings as a plugin.

  .use(LanguageDetector)
  .use(initReactI18next)

  // Initialize the i18next instance.
  .init({
    detection: detectionOptions,
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.
    // lng: "en",

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here.
    fallbackLng: "en",
    supportedLngs: ["en", "de"],
    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      en: { translation: en },
      de: { translation: de },
    },
  });

export default i18n;

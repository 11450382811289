import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import {
    SmartTagItemType,
    DetacherUnitUpdateType,
    SmartTagResponse
} from '@/types';
import { useGlobalNotification, useSession, useStoreContext } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

export const useSmartTag = () => {
    const queryClient = useQueryClient();
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { selectedStoreId } = useStoreContext();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session } = useSession();

    // Fetch SmartTags
    const {
        data: SmartTags = [],
        isLoading,
        isError
    } = useQuery<SmartTagResponse>(
        ['SmartTags', selectedStoreId],
        async () => {
            const response = await serviceAPI('device').get(
                `/battery_levels?numberOnPage=${10}&pageNumber=${0}&store_id=${selectedStoreId}`
            );

            console.log(response);

            if (response.status !== 200) {
                throw new Error('Failed to fetch DUs');
            }

            return response.data;
        },
        {
            enabled: !!session?.access_token,
            staleTime: 5 * 60 * 1000,
            onError: (error: any) => {
                setErrorMessage(
                    error.response?.data?.error || 'Failed to fetch DUs'
                );
            }
        }
    );

    // Update SmartTag
    const updateSmartTag = useMutation(
        (updatedDetacherUnit: DetacherUnitUpdateType): Promise<any> =>
            serviceAPI('device').put(
                `/appliances/${updatedDetacherUnit.name}`,
                updatedDetacherUnit
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['DetacherUnits']);
                setGlobalSuccess(t('wifi.wifiUpdateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(error.response?.data?.error);
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to update Detacher Unit'
                );
            }
        }
    );

    return {
        SmartTags,
        isLoading,
        isError,
        errorMessage,
        updateSmartTag
    };
};

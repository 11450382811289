import { Chart } from "@/components";
import getChartOptions from "@/components/chart/chartOptions";
import { createSensorsHistory } from "@/hooks/data/mock";

import React from "react";

import { DatePickerWithPresets } from "@/components/ui/DatePicker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";

function SmartTagsInUseHistoricalChart() {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  const usedHistoricalData = createSensorsHistory(
    ["Safer", "Mini Safer", "Fashion", "Bottle", "Spider"],
    80
  );

  return (
    <div className="relative pt-2 mt-5  ">
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-3 mb-3 md:absolute px-3 top-[-15px] left-4  w-full">
        <DatePickerWithPresets dateRange={date} setDateRange={setDate} />
      </div>{" "}
      <Chart
        type={"line"}
        data={usedHistoricalData.sensorsHistory}
        options={getChartOptions(usedHistoricalData.lastSixMonths, "line")}
      />
    </div>
  );
}

export default SmartTagsInUseHistoricalChart;

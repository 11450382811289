import React, { useEffect, useState } from 'react';
import { links } from './navItemsData';
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarRail,
    useSidebar
} from '@/components/ui/sidebar';
import CopyRight from '@/components/common/CopyRight';
import { VERSION_NUMBER } from '@/hooks/data/Constants';
import { NavMain } from './NavMain';
import { ComboBoxResponsive } from '@/components/ui/combobox';
import { useStoreContext } from '@/contexts';
import { StoreType } from '@/types';

interface AppSidebarProps extends React.ComponentProps<typeof Sidebar> {
    merchantId: string;
    stores: StoreType[];
}

type StatusesType = {
    value: string;
    label: string;
}[];

function AppSidebar({ merchantId, stores = [], ...props }: AppSidebarProps) {
    const { open } = useSidebar();
    const { setSelectedStoreId, selectedStoreId } = useStoreContext();
    const [transformedData, setTransformedData] = useState<StatusesType>([]);

    const handleStoreChange = (storeId: string) => {
        const selectedStore = stores?.find(
            (store) => store?.storeId.toString() === storeId
        );
        if (selectedStore) {
            setSelectedStoreId(storeId);
        }
    };

    useEffect(() => {
        if (stores.length > 0) {
            const data = stores?.map((store) => ({
                value: store?.storeId?.toString(),
                label: store?.storeName
            }));
            if (data) {
                setTransformedData(data);
            }
        }
    }, [stores]);

    return (
        <Sidebar collapsible="icon" {...props} className="bg-primary-600">
            <SidebarHeader className="flex justify-center items-center">
                <img
                    src={`./images/logo-w-${merchantId}.png`}
                    width={141}
                    height={34}
                    className=" w-24 h-auto"
                    alt="logo"
                />
            </SidebarHeader>
            <SidebarContent>
                <div className="mt-2 mb-3">
                    <h3 className="text-primary-50 font-medium pb-1">Store</h3>
                    <ComboBoxResponsive
                        onChange={(storeId) => handleStoreChange(storeId!)}
                        disabled={stores?.length === 0}
                        hideLabel={!open}
                        statuses={transformedData}
                        showPrefixIcon
                        className="bg-primary-800 h-12 border-primary-500 text-primary-200 text-[16px] hover:bg-primary-700 hover:text-primary-100 px-3 rounded-lg"
                        value={selectedStoreId?.toString()}
                    />
                </div>

                <NavMain items={links} />
            </SidebarContent>
            <SidebarFooter>
                {open && <CopyRight version={VERSION_NUMBER} mode="light" />}
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    );
}

export default AppSidebar;

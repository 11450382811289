import * as React from "react";
import { Eye, EyeClosed } from "lucide-react";

import { cn } from "@/utils";

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<"input">>(
  ({ className, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const togglePassword = () => {
      setShowPassword(!showPassword);
    };

    const showPasswordIcon = (
      <button
        type="button"
        onClick={togglePassword}
        className="absolute inset-y-0 right-5 flex items-center  z-50 ps-4"
      >
        {showPassword ? (
          <Eye className="size-4 text-neutral-500" />
        ) : (
          <EyeClosed className="size-4 text-neutral-500" />
        )}
      </button>
    );
    return (
      <>
        <input
          type={showPassword ? "text" : type}
          className={cn(
            "flex h-12 w-full border-gray-300 focus:border-primary-500	 focus:ring-primary-500 rounded-lg   bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
            className
          )}
          ref={ref}
          {...props}
        />
        {type === "password" && showPasswordIcon}
      </>
    );
  }
);
Input.displayName = "Input";

export { Input };

import axios from 'axios';
import config from './configs';

let isRefreshing = false; // Prevent multiple refresh requests
let refreshSubscribers: Array<(token: string) => void> = [];

// Notify all subscribers with the new token
const onRefreshed = (newToken: string) => {
    refreshSubscribers.forEach((callback) => callback(newToken));
    refreshSubscribers = [];
};

// Map of base URLs
const baseURLs: Record<string, string> = {
    device: config.REACT_APP_BACKEND_DEVICES_URL || '',
    analytic: config.REACT_APP_BACKEND_ANALYTICS_URL || '',
    user: config.REACT_APP_BACKEND_USERS_URL || ''
};

// Function to dynamically set the baseURL
const getBaseURL = (urlKey: string): string => {
    return baseURLs[urlKey] || baseURLs.default;
};

// Create Axios instance
const axiosInstance = axios.create({
    timeout: 5000
});

// Add request interceptor for Authorization header
axiosInstance.interceptors.request.use((config) => {
    const token =
        sessionStorage.getItem('access_token') ||
        localStorage.getItem('access_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Add response interceptor for handling token expiry
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log('Token Error:', error);
        console.log('Token Error Response:', error.response);

        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (isRefreshing) {
                return new Promise((resolve) => {
                    refreshSubscribers.push((newToken: string) => {
                        originalRequest.headers.Authorization = `Bearer ${newToken}`;
                        resolve(axiosInstance(originalRequest));
                    });
                });
            }

            isRefreshing = true;
            const refreshToken =
                sessionStorage.getItem('refresh_token') ||
                localStorage.getItem('refresh_token');

            console.log('Refreshing token...');

            try {
                // Use the Keycloak token endpoint to refresh the access token
                const { data } = await axios.post(
                    `${config.REACT_APP_AUTH_AUTHORITY}/protocol/openid-connect/token`,
                    new URLSearchParams({
                        grant_type: 'refresh_token',
                        client_id: config.REACT_APP_AUTH_CLIENT_ID || '',
                        refresh_token: refreshToken || ''
                    })
                );

                const newAccessToken = data.access_token;
                // sessionStorage.setItem('access_token', newAccessToken);
                // if (localStorage.getItem('access_token')) {
                //     localStorage.setItem('access_token', newAccessToken);
                // }

                console.log('The token has been refreshed!');

                isRefreshing = false;
                onRefreshed(newAccessToken);

                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                isRefreshing = false;
                refreshSubscribers = [];
                console.error('Token refresh failed:', refreshError);
                window.location.href = '/';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

// Function to make requests with dynamic baseURL
const serviceAPI = (urlKey: string) => {
    const instance = axiosInstance;
    instance.defaults.baseURL = getBaseURL(urlKey);
    return instance;
};

export default serviceAPI;

import { useQuery } from "react-query";
import { fetchSensorUsageCashiersData } from "@/services";
import { useSession } from "@/contexts";

type QueryParams = {
  startDate: string;
  endDate: string;
  sensorType: string;
  storeId: string;
};

export default function useSensorUsageCashiersData(params: QueryParams) {
  const { session, user } = useSession();
  const { startDate, endDate, sensorType, storeId } = params;

  if (!user) {
    throw new Error("No user found");
  }
  if (!session) {
    throw new Error("No session found");
  }

  const accessToken = session?.access_token;
  return useQuery(
    ["sensorUsageCashiersData", startDate, endDate, sensorType, storeId], // Unique query key
    () =>
      fetchSensorUsageCashiersData({
        startDate,
        endDate,
        storeId,
        accessToken,
        sensorType,
      }),
    {
      enabled: !!accessToken && !!storeId, // Only run if accessToken is available
      retry: 2, // Optional: retry up to 2 times if the request fails
      staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
      cacheTime: 10 * 60 * 1000, // Optional: cache the data for 10 minutes
    }
  );
}

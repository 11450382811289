import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { DetacherUnitType, DetacherUnitUpdateType } from '@/types';
import { useGlobalNotification, useSession, useStoreContext } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

export const useDetacherUnit = () => {
    const queryClient = useQueryClient();
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { selectedStoreId } = useStoreContext();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session } = useSession();

    // Fetch DUs
    const {
        data: DetacherUnits = [],
        isLoading,
        isError
    } = useQuery<DetacherUnitType[]>(
        ['DetacherUnits', selectedStoreId],
        async () => {
            // Fetch LastOnline data
            const lastOnlineResponse = await serviceAPI('device').get(
                `/appliances/lastonline?store_id=${selectedStoreId}`
            );
            if (lastOnlineResponse.status !== 200) {
                throw new Error('Failed to fetch LastOnline');
            }
            const lastOnlineData = lastOnlineResponse.data;

            const detacherUnitsResponse = await serviceAPI('device').get(
                `/appliances?store_id=${selectedStoreId}`
            );

            if (detacherUnitsResponse.status !== 200) {
                throw new Error('Failed to fetch DUs');
            }
            const detacherUnitsData = detacherUnitsResponse.data?.appliances;

            const zones = detacherUnitsResponse.data?.zones.map(
                (item: string, index: number) => {
                    return {
                        id: index,
                        name: item
                    };
                }
            );

            // Combine the two datasets
            const transformedData = detacherUnitsData.map(
                (unit: DetacherUnitType) => ({
                    ...unit,
                    zones,
                    lastOnline: lastOnlineData[unit.name]?.LastOnline || null,
                    lastOnlineLabel:
                        lastOnlineData[unit.name]?.LastOnlineLabel || false
                })
            );
            return transformedData;
        },
        {
            enabled: !!session?.access_token,
            staleTime: 5 * 60 * 1000,
            onError: (error: any) => {
                setErrorMessage(
                    error.response?.data?.error || 'Failed to fetch DUs'
                );
            }
        }
    );

    // Update WifiConfig
    const updateDetacherUnit = useMutation(
        (updatedDetacherUnit: DetacherUnitUpdateType): Promise<any> =>
            serviceAPI('device').put(
                `/appliances/${updatedDetacherUnit.name}`,
                updatedDetacherUnit
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['DetacherUnits']);
                setGlobalSuccess(t('wifi.wifiUpdateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(error.response?.data?.error);
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to update Detacher Unit'
                );
            }
        }
    );

    return {
        DetacherUnits,
        isLoading,
        isError,
        errorMessage,
        updateDetacherUnit
    };
};

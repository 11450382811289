import { Chart } from "@/components";
import getChartOptions from "@/components/chart/chartOptions";
// import { SMART_SENSORS_TYPES } from "@/app/lib/data/Constants";
import { createSensorsHistory } from "@/hooks/data/mock";
// import Configs from "@/app/utils/config";
// import dateFormatter from "@/app/utils/dateFormatter";
import { useState } from "react";

import { DatePickerWithPresets } from "@/components/ui/DatePicker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";
// import { useStoreContext } from "@/contexts";

function AssignedVsUsedSensorHistoricalChart() {
  // const { selectedStoreId } = useStoreContext();
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  const usedVsAssignedHistoricalData = createSensorsHistory(
    ["Used", "Assigned"],
    80
  );

  return (
    <div className="relative pt-2 mt-5  ">
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-3 mb-3 md:absolute px-3 top-[-15px] left-4  w-full">
        <DatePickerWithPresets dateRange={date} setDateRange={setDate} />
      </div>{" "}
      <Chart
        type={"line"}
        data={usedVsAssignedHistoricalData.sensorsHistory}
        options={getChartOptions(
          usedVsAssignedHistoricalData.lastSixMonths,
          "line"
        )}
      />
    </div>
  );
}

export default AssignedVsUsedSensorHistoricalChart;

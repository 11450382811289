import { Outlet, Navigate } from 'react-router-dom';
import { useSession } from '@/contexts'; // Adjust if needed
import setTheme from '@/utils/setTheme';
import themes from '@/theme';
import { ThemeType } from '@/types';
import { useEffect, useState } from 'react';

const ProtectedRoute = () => {
    const { session, loading, user } = useSession();

    // Set the theme after user data is available
    useEffect(() => {
        if (user?.merchantId !== undefined) {
            setTheme(
                themes[user.merchantId as keyof typeof themes] as ThemeType
            );
        }
    }, [user?.merchantId]);

    if (loading) return <p>Loading session...</p>;

    // Check if the session has an active access token
    const checkAccessTokenExpiry = () => {
        if (session?.expires_in) {
            const expiryDate = new Date(session.expires_in * 1000);
            if (expiryDate < new Date()) {
                return false;
            }
            return true;
        }
    };

    const isAuthenticated =
        Boolean(session?.access_token) && checkAccessTokenExpiry();

    // Ensure we're not updating state while rendering
    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;

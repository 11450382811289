const generateRandomValues = (x: number, count = 6) => {
  const randomValues = Array.from({ length: count }, () => Math.random());
  const sum = randomValues.reduce((acc, val) => acc + val, 0);
  const normalizedValues = randomValues.map((val) => (val / sum) * x);
  const finalValues = normalizedValues.map((val) => Math.round(val));
  const diff = x - finalValues.reduce((acc, val) => acc + val, 0);
  finalValues[0] += diff;

  return finalValues;
};

const getLastSixMonths = () => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const now = new Date();
  const months = [];

  for (let i = 5; i >= 0; i--) {
    const d = new Date(now.getFullYear(), now.getMonth() - i, 1);
    months.push(monthNames[d.getMonth()]);
  }

  return months;
};

const createSensorsHistory = (sensorNames: string[], x: number) => {
  const sensorsHistory = sensorNames.map((name: string) => ({
    name,
    data: generateRandomValues(x),
  }));

  const lastSixMonths = getLastSixMonths();

  return { sensorsHistory, lastSixMonths };
};

export { createSensorsHistory };

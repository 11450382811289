import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useState } from 'react';
import { WifiConfig, CreateWiFiInputType, UpdateWiFiInputType } from '@/types';
import Configs from '@/utils/configs';
import { useGlobalNotification, useSession } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

export const useWifi = () => {
    const queryClient = useQueryClient();
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session } = useSession();

    // Fetch users
    const {
        data: WifiConfigs = [],
        isLoading,
        isError
    } = useQuery<WifiConfig[]>(
        ['wifiConfigs'],
        async () => {
            const response = await serviceAPI('device').get(
                `/wifiConfiguration`
            );

            if (response.status !== 200) {
                throw new Error('Failed to fetch stores');
            }

            const transformedData = [];
            for (const key in response.data) {
                transformedData.push({
                    id: key,
                    ssid: JSON.parse(response.data[key].wifiConfigJson).ssid,
                    ...response.data[key]
                });
            }
            return transformedData;
        },
        {
            enabled: !!session?.access_token,
            staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
            onError: (error: any) => {
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to fetch WiFi configurations'
                );
            }
        }
    );

    // Create WifiConfig
    const createWifiConfig = useMutation(
        (newConfig: CreateWiFiInputType) => {
            console.log('Authorization token:', session?.access_token); // Log token
            return axios.post(
                `${Configs.REACT_APP_BACKEND_DEVICES_URL}/wifiConfiguration`,
                newConfig,
                {
                    headers: {
                        Authorization: `Bearer ${session?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['wifiConfigs']);
                setGlobalSuccess(t('wifi.wifiCreateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(error.response?.data?.error);
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to create wifi config'
                );
            }
        }
    );

    // Update WifiConfig
    const updateWifiConfig = useMutation(
        (updatedConfig: UpdateWiFiInputType): Promise<any> =>
            axios.put(
                `${Configs.REACT_APP_BACKEND_DEVICES_URL}/wifiConfiguration?wifiConfigId=${updatedConfig.id}`,
                updatedConfig,
                {
                    headers: {
                        Authorization: `Bearer ${session?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['wifiConfigs']);
                setGlobalSuccess(t('wifi.wifiUpdateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(error.response?.data?.error);
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to update wifi config'
                );
            }
        }
    );

    // Delete WifiConfig
    const deleteWifiConfig = useMutation(
        (id: string) =>
            axios.delete(
                `${Configs.REACT_APP_BACKEND_DEVICES_URL}/wifiConfiguration?wifiConfigId=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${session?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            ),

        {
            onSuccess: () => {
                queryClient.invalidateQueries(['wifiConfigs']);
                setGlobalSuccess(t('wifi.wifiDeleteSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(error.response?.data?.error);
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to delete wifi config'
                );
            }
        }
    );

    return {
        WifiConfigs,
        isLoading,
        isError,
        errorMessage,
        createWifiConfig,
        updateWifiConfig,
        deleteWifiConfig
    };
};

import React, { ReactElement, useState } from 'react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    SortingState,
    getSortedRowModel,
    ColumnFiltersState,
    getFilteredRowModel
} from '@tanstack/react-table';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';

import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from '@/components/ui/pagination';
import { useTranslation } from 'react-i18next';

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    formComponent?: ReactElement;
    searchField: string;
    searchPlaceholder: string;
}

function DataTable<TData, TValue>({
    columns,
    data,
    formComponent,
    searchField,
    searchPlaceholder
}: //   newButtonLabel = "",
DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const { t } = useTranslation();

    const FormComponent = formComponent || null;
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters
        }
    });
    // console.log("table", table.getPageCount());
    const tablePages = Array.from(
        { length: table.getPageCount() },
        (_, i) => i + 1
    );

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-4 ">
                <div className="lg:col-span-2">
                    <Input
                        placeholder={searchPlaceholder}
                        value={
                            (table
                                .getColumn(searchField)
                                ?.getFilterValue() as string) ?? ''
                        }
                        onChange={(event) =>
                            table
                                .getColumn(searchField)
                                ?.setFilterValue(event.target.value)
                        }
                        className="max-w-sm"
                    />
                </div>
                <div className="width-full flex justify-end">
                    {FormComponent}
                </div>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext()
                                                  )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && 'selected'
                                    }
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns?.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <span className=" hidden md:block text-xs w-32 text-gray-400 absolute left-5">
                    {`${t('common.totalResults')}: ${data.length}`}
                </span>
                <Pagination className="text-gray-500">
                    <PaginationContent>
                        <PaginationItem>
                            <PaginationPrevious
                                title={t('common.previous')}
                                href="#"
                                onClick={() => {
                                    if (currentPage > 0) {
                                        table.previousPage();
                                        setCurrentPage((prev) => prev - 1);
                                    }
                                }}
                                className={
                                    currentPage <= 0
                                        ? 'pointer-events-none opacity-50'
                                        : undefined
                                }
                            />
                        </PaginationItem>
                        {tablePages.map((item) => (
                            <PaginationItem key={item}>
                                <PaginationLink
                                    isActive={currentPage === item - 1}
                                    href={'#'}
                                    onClick={() => {
                                        table.setPageIndex(item - 1);
                                        setCurrentPage(item - 1);
                                    }}
                                >
                                    {item}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem>
                            <PaginationNext
                                title={t('common.next')}
                                href="#"
                                onClick={() => {
                                    if (currentPage < tablePages.length - 1) {
                                        table.nextPage();
                                        setCurrentPage((prev) => prev + 1);
                                    }
                                }}
                                className={
                                    currentPage >= tablePages.length - 1
                                        ? 'pointer-events-none opacity-50'
                                        : undefined
                                }
                            />
                        </PaginationItem>
                    </PaginationContent>
                </Pagination>
            </div>
        </div>
    );
}

export default DataTable;
